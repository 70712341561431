/*

Theme: Dashly
Product Page: https://themes.getbootstrap.com/product/dashly-admin-dashboard-template/
Author: Webinning
Author URI: https://webinning.co.uk
Support: support@dashly-theme.com

---

Copyright 2022 Webinning

*/

'use strict';

// Bootsrap
import 'bootstrap';

// Theme
import './bootstrap';
import './tooltip';
import './dropdown';
import './validation';
import './table';
import './wizard';
import './show-password';
import './tab-link';

// Vendors
import './vendors/dragula';
import './vendors/dropzone';
import './vendors/list';
import './vendors/flatpickr';
import './vendors/fullcalendar';
import './vendors/gumshoejs';
import './vendors/picmo';
import './vendors/tom-select';
import './vendors/quill';
import './vendors/inputmask';
import './vendors/zxcvbn';
import './vendors/clipboard';
import './vendors/highlightjs';

// User
import './user';
