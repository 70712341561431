// inputmask.js

'use strict';

const inputmask = document.querySelectorAll('[data-inputmask]');
const inputmaskContainers = document.querySelectorAll('[data-inputmask-container]');


import(/* webpackChunkName: 'inputmask' */ 'inputmask')
.then(({default: Inputmask}) => {
    if (inputmask.length) {
        Inputmask().mask(inputmask);
    }
    window.Inputmask = Inputmask;

    // // Add new inputmasks when the inputmask container is triggered
    // if (inputmaskContainers.length) {
    //     for (let i = 0; i < inputmaskContainers.length; i++) {
    //         inputmaskContainers[i].addEventListener('innieputmask', function(e) {
    //             const inputmask = e.detail.querySelectorAll('[data-inputmask]');
    //             Inputmask().mask(inputmask);
    //         });
    //     }
    // }
})
.catch(console.warn);
