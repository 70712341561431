// Dragula

'use strict';

function makeDragulas(containers, extraOptions, windowDragContainer) {
    if (containers.length) {
        import(/* webpackChunkName: 'dragula' */ 'dragula')
        .then(({default: dragula}) => {

            window[windowDragContainer] = dragula(extraOptions).on('drop', function (draggedItem, target, _source, _currentSibling) {
                // Update the order input
                if (draggedItem.hasAttribute('data-sortable-order')) {
                    var inputClassName = draggedItem.dataset.sortableOrder;
                    if (typeof inputClassName != 'undefined') {

                        // Get all items that need to be ordered
                        var orderableItems = Array.prototype.filter.call(draggedItem.parentElement.children, function (item) {
                            return item.matches('.drag-item');
                        });

                        if (orderableItems.length) {
                            // Loop through items and assign order
                            orderableItems.forEach((item, i) => {
                                var input = item.querySelector('.' + inputClassName);
                                if (typeof input != 'undefined') {
                                    input.value = i;
                                }
                            });
                        }
                    }
                }

                // Change input names to adhere to list number
                if (draggedItem.hasAttribute('data-sortable-change-names')) {

                    var renameData = draggedItem.dataset.sortableChangeNames;
                    if (typeof renameData != 'undefined') {

                        renameData = JSON.parse(renameData);
                        var oldListNum = _source.dataset.listNum;

                        if (typeof renameData.baseName != 'undefined') {

                            // Check if item has changed lists
                            if (typeof draggedItem.parentElement.dataset.listNum != 'undefined') {
                                var newListNum = draggedItem.parentElement.dataset.listNum;
                                if (newListNum != oldListNum) {

                                    // Get all inputs
                                    var itemInputs = draggedItem.querySelectorAll('input');

                                    if (itemInputs.length) {

                                        // Loop through inputs and change name
                                        itemInputs.forEach((input, i) => {
                                            var baseName = renameData.baseName;
                                            var oldBaseName = baseName.replace('$$$', oldListNum);
                                            var newBaseName = baseName.replace('$$$', newListNum);

                                            input.attributes.name.value = input.attributes.name.value.replace(oldBaseName, newBaseName);
                                        });
                                    }
                                }
                            }
                        }
                    }
                }
            });

            // Add draggable item to correct dragula list -> Lists to lists and items to items
            containers.forEach((container, i) => {
                window[windowDragContainer].containers.push(container);
            });

            window.Dragula = dragula;
        })
        .catch(console.warn);
    }
}

const containers = document.querySelectorAll('[data-sortable]');
window['makeDragContainersItems'] = function makeDragContainersItems (containers) {
    makeDragulas(containers, {
        moves : function(el, source, handle, sibling) {
            if (el.classList.contains('no-drag')) {
                return false;
            }
            return true;
        }
    }, 'dragContainersItems');
}
window['makeDragContainersItems'](containers);

const sortableContainers = document.querySelectorAll('[data-sortable-containers]');
window['makeDragContainersLists'] = function makeDragContainersLists(sortableContainers) {
    makeDragulas(sortableContainers, {
        moves : function(el, source, handle, sibling) {
            if (handle.classList.contains('handle') || handle.parentElement.classList.contains('handle')) {
                return true;
            }

            return false;
        }
    }, 'dragContainersLists');
}
window['makeDragContainersLists'](sortableContainers);



// function makeDragulas(containers, extraOptions, windowDragContainer) {
//     if (containers.length) {
//         import(/* webpackChunkName: 'dragula' */ 'dragula')
//         .then(({default: dragula}) => {
//             window[windowDragContainer] = [];
//             containers.forEach((container) => {
//                 let sortables = container.dataset.sortable,
//                     userOptions = container.dataset.sortableOptions ? JSON.parse(container.dataset.sortableOptions) : {},
//                     list = [];

//                 var options = {
//                     ...userOptions,
//                     ...extraOptions
//                 };

//                 // console.log(extraOptions);
//                 // console.log(options);

//                 if (sortables) {
//                     sortables = JSON.parse(sortables);

//                     for (let i = 0; i < sortables.length; i++) {
//                         list.push((document.querySelector('#' + sortables[i])));
//                     }
//                 }
//                 else {
//                     list = [containers[0]];
//                 }

//                 var igor = dragula(list, extraOptions).on('drop', function (item, target, _source, _currentSibling) {
//                     console.log('HALLO');
//                     // if (item.hasAttribute('data-sortable-order')) {
//                     //     var inputClass = item.dataset.sortableOrder;

//                     //     if (typeof inputClass != 'undefined') {
//                     //         var items = item.parentElement.querySelectorAll('.drag-item');
//                     //         items.forEach((card, i) => {
//                     //             console.log(card);
//                     //             var input = card.querySelector('.' + inputClass);
//                     //             if (typeof input != 'undefined') {
//                     //                 console.log(input);
//                     //                 input.value = i;
//                     //             }
//                     //         });
//                     //     }
//                     // }
//                 });

//                 window[windowDragContainer].push(igor);

//                 console.log(window[windowDragContainer]);
//             });

//             window.Dragula = dragula;
//         })
//         .catch(console.warn);
//     }
// }


// const containers = document.querySelectorAll('[data-sortable]');
// console.log(containers);
// makeDragulas(containers, {
//     moves : function(el, source, handle, sibling) {
//         console.log('item');
//         if (el.classList.contains('no-drag')) {
//             return false;
//         }
//         return true;
//     }
// }, 'dragContainersItems');


// // const sortableContainers = document.querySelectorAll('[data-sortable-containers]');
// // makeDragulas(sortableContainers, {
// //     moves : function(el, source, handle, sibling) {
// //         console.log('list');
// //         if (handle.classList.contains('handle') || handle.parentElement.classList.contains('handle')) {
// //             return true;
// //         }

// //         return false;
// //     }
// // }, 'dragContainersLists');

// // if (sortableContainers.length) {
// //     import(/* webpackChunkName: 'dragula' */ 'dragula')
// //     .then(({default: dragula}) => {
// //         window.dragContainerContainers = [];
// //         sortableContainers.forEach((container) => {

// //             let sortables = container.dataset.sortable,
// //                 userOptions = container.dataset.sortableOptions ? JSON.parse(container.dataset.sortableOptions) : {},
// //                 list = [];

// //             userOptions.moves = function(el, source, handle, sibling) {
// //                 // console.log(el, handle);
// //                 return (handle.classList.contains('handle') || handle.parentElement.classList.contains('handle'));
// //             };

// //             if (sortables) {
// //                 sortables = JSON.parse(sortables);

// //                 for (let i = 0; i < sortables.length; i++) {
// //                     list.push((document.querySelector('#' + sortables[i])));
// //                 }
// //             }
// //             else {
// //                 list = [sortableContainers[0]];
// //             }

// //             window.dragContainers.push(igor);
// //         });
// //     })
// //     .catch(console.warn);
// // }

