// flatpickr

'use strict';

const datepickers = document.querySelectorAll('[data-flatpickr]');

import(/* webpackChunkName: 'flatpickr' */ 'flatpickr')
    .then(({default: flatpickr}) => {
        import(/* webpackChunkName: 'flatpickr' */ 'flatpickr/dist/l10n/nl.js')
            .then(({default: locale}) => {


                if (datepickers.length) {
                    datepickers.forEach((datepicker) => {
                        const userOptions = datepicker.dataset.flatpickr ? JSON.parse(datepicker.dataset.flatpickr) : {};

                        const onChangeFunct = datepicker.dataset.flatpickrOnchange;
                        if (typeof onChangeFunct != 'undefined' && onChangeFunct != null) {
                            userOptions.onChange = window[datepicker.dataset['flatpickrOnchange']];
                        }

                        const defaultOptions = {
                            locale: locale.nl,
                            time_24hr: true,
                            dateFormat: 'Y-m-d',
                            prevArrow: '<svg height="9" height="9" class="fill-transparent" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.cls-1{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;fill-rule:evenodd;}</style></defs><title>arrow-left-1</title><path class="cls-1" d="M16.25,23.25,5.53,12.53a.749.749,0,0,1,0-1.06L16.25.75"/></svg>',
                            nextArrow: '<svg height="9" height="9" class="fill-transparent" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.cls-1{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;fill-rule:evenodd;}</style></defs><title>arrow-right-1</title><path class="cls-1" d="M5.5.75,16.22,11.47a.749.749,0,0,1,0,1.06L5.5,23.25"/></svg>',
                        }

                        const options = {
                            ...defaultOptions,
                            ...userOptions
                        };

                        flatpickr(datepicker, options);
                    });
                }

                window.Flatpickr = flatpickr;
                window.FlatpickrLocale = locale;
            })
            .catch(console.warn);
    });
