// Dropdown

// Override Bootstrap dropdown with 'preventOverflow' option
const dropdowns = document.querySelectorAll('.dropdown-toggle');

if(dropdowns.length) {
    dropdowns.forEach((dropdown) => {
        return new Dropdown(dropdown, {
            popperConfig: (config) => {
                const preventOverflow = config.modifiers.find(({ name }) => name === 'preventOverflow');

                if(typeof preventOverflow != 'undefined') {
                    preventOverflow.options = {
                        ...preventOverflow.options,
                        altAxis: true
                    }
                }
    
                return config;
            }
        });
    });
}