// Tom Select

'use strict';

import * as helper from '../helpers';

const selects = document.querySelectorAll('[data-select]');
const forms = document.querySelectorAll('.needs-validation');

import(/* webpackChunkName: 'tom-select' */ 'tom-select')
    .then(({default: TomSelect}) => {
        
        window['activateTomSelect'] = function activateTopSelect(select) {
            const userOptions = select.dataset.select ? JSON.parse(select.dataset.select) : {};

            let optionTemplate = select.dataset.optionTemplate,
                itemTemplate = select.dataset.itemTemplate;

            const defaultOptions = {
                maxOptions: null,
                onChange: function() {
                    this.wrapper.classList.toggle('is-invalid', !this.isValid);
                }
            };

            const templateOptions = {
                render: optionTemplate ? {
                    option: (data, escape) => {
                        return optionTemplate.replace(/\[\[.+?\]\]/g, (match) => {
                            return escape(data[helper.getSubstringBetween(match, '[[', ']]')])
                        });
                    },
                    item: (data, escape) => {
                        return itemTemplate.replace(/\[\[.+?\]\]/g, (match) => {
                            return escape(data[helper.getSubstringBetween(match, '[[', ']]')])
                        });
                    },
                    no_results:function(data,escape){
                        return '<div class="no-results">Geen resultaten gevonden</div>';
                    }
                } : {
                    no_results:function(data,escape){
                        return '<div class="no-results">Geen resultaten gevonden</div>';
                    }
                }
            }

            const options = {
                ...defaultOptions,
                ...templateOptions,
                ...userOptions
            };

            let selectEl = new TomSelect(select, options);
            selectEl.on('item_add', function(value, pill) {
                this.control_input.value = '';
            });

            if(forms.length) {
                forms.forEach((form) => {
                    form.addEventListener('submit', (e) => {
                        selectEl.wrapper.classList.toggle('is-invalid', !selectEl.isValid);

                        if (!form.checkValidity()) {
                            e.preventDefault()
                            e.stopPropagation()
                        }

                        form.classList.add('was-validated');
                    }, false);
                });
            }
        }

        if (selects.length) {
            selects.forEach((select) => {
                window['activateTomSelect'](select);
            });
        }

        window.TomSelect = TomSelect;
    })
    .catch(console.warn);
