// Quill

'use strict';

const editors = document.querySelectorAll('[data-quill]');
window['quillies'] = [];
window['quillDefaultOptions'] = {
    theme: 'snow',
    modules: {
        toolbar: [
            ['bold', 'italic', 'underline']
        ]
    }
};
window['quillAdminOptions'] = {
    theme: 'snow',
    modules: {
        toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline'],
            ['link', 'blockquote'],
            [
                {
                    list: 'ordered'
                },
                {
                    list: 'bullet'
                }
            ]
        ]
    }
};

if (editors.length) {
    import(/* webpackChunkName: 'quill' */ 'quill')
    .then(({default: Quill}) => {
        editors.forEach((editor) => {
            const userOptions = editor.dataset.quill ? JSON.parse(editor.dataset.quill) : {};

            var editorOptions = window['quillDefaultOptions'];

            if (userOptions.admin_editor) {
                editorOptions = window['quillAdminOptions'];
            }

            const options = {
                ...editorOptions,
                ...userOptions
            };

            let quillEditor = new Quill(editor, options);
            let targetInputId = editor.getAttribute('data-quill-target');

            if (targetInputId && targetInputId.length > 0) {
                let targetInput = document.getElementById(targetInputId);

                if (!targetInput) throw "Target input id was invalid .err.";
                quillEditor.on('text-change',
                    () => {
                        targetInput.value = editor.children[0].innerHTML;
                    });
            }

            window['quillies'][targetInputId] = quillEditor;
        });

        window.Quill = Quill;
    })
    .catch(console.warn);
}
